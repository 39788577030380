.share-icon-collections button{
    fill: white;
    margin-left: 0.5rem;
    width: 20px;
    height: 20px;
    background-color: transparent;
}

.share-icon-collections svg {
    fill: white !important;
}

.alertSeccion{
    font-weight: bold;
}

.share-icon {
    fill: white;
    margin-left: 0.5rem;
    width: 20px;
    height: 20px;
}

.alertSeccion{
    position: absolute;
}