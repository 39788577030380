.Navbar {
  width: 100%;
  border: 0px;
  border-bottom: 0.05rem;
  border-color: #EBEBEB;
  border-style: solid;
}

.NavDropdown{
  transition-duration: 1s ;
}


@media (min-width: 576px) {
  .offcanvas .offcanvas-body {
    justify-content: end;
  }
}