.footer-header{
    background: #ECECEC;
    width: 100%;
    height: auto;
    font-size: large;
    color: #292929;
    padding-top: 1.5rem;
    padding-bottom: 1.5em;
    border-bottom-color: #27AE60;
    border-bottom: 4px solid #27AE60;
}

.footer-header p {
    align-items: center;
    
    font-size: larger;
}

.footer-button{
    width: 100% !important;
    height: 100% !important;
    font-size: large !important;
    font-weight: 500 !important;
    padding: 1rem 0rem 1rem 0rem !important;
    align-self: flex-end !important;
    border-radius: 0.2rem !important;
    border-color: #27AE60 !important;
    background-color: #27AE60 !important;
    border-bottom-color: #007C2E !important;
    border-bottom-width: 2px !important;    
}

.footer-button:hover {
    border-radius: 0.2rem !important;
    border-color: #292929 !important;
    background-color: #292929 !important;
    border-bottom-color: black !important;
    border-bottom-width: 2px !important;
  }

.footer{
    background: #292929;
    width: 100%;
    height: auto;
    z-index: -1;
    font-size: small;
    border-color: #292929;
}

.footerContainer{
    padding: 2rem 0 0 0;
    color: #DDF2FD;
}

ul {
    list-style: none;
    padding: 0; /* O ajusta el relleno según sea necesario */
    padding-left: 0px !important; 
}

ul li {
    text-decoration: none; /* Elimina el subrayado de los enlaces en los elementos li */
    color: #DDF2FD; /* Color del texto para los enlaces en los elementos li */
    margin-bottom: 0.5rem;
}
ul li a{
    color: #DDF2FD;
    text-decoration: none; /* Elimina el subrayado de los enlaces en los elementos li */

}

ul li svg{
    margin-right: 0.5rem;
    width: 20px;
    height: 20px;
}

.icon{
    color: #DDF2FD;
}

.midCol{
    border: 0.01rem solid grey;
    border-top: none;
    border-bottom: none;
}

.footerContainer img{
    width: 60%;
}

@media (max-width: 767px) {
    .midCol {
      text-align: center;
    }
  }