.Switch button{
    border-color: #27AE60;
    background-color: #27AE60;
    border-radius: 4px !important;
}

.Switch button:hover {
    background-color: #176b3a;
    border-color: #176b3a; 
}

.Switch button.selected {
    background-color: #27AE60;
    border-color: #27AE60;
  }